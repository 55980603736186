(function () {
  // console.log('avant', Array.prototype.reduce);

  for (var standardObjectName in window.savedFunctions) {
    if ({}.hasOwnProperty.call(window.savedFunctions, standardObjectName)) {
      var functionsList = window.savedFunctions[standardObjectName];

      for (var i = 0, length = functionsList.length; i < length; i += 1)
      {
        var currentFunctionObj = functionsList[i];

        window[standardObjectName].prototype[currentFunctionObj.functionName] = currentFunctionObj.functionCode;
      }
    }
  }
  // console.log('apres', Array.prototype.reduce);
}());